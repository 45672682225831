<template>
  <v-card-text class="printHTML px-5">
    <table class="header-table">
        <tr>
          <td colspan="2">
            <table>
              <tr>
                <td>
                  <img
                    class="logo"
                    src="/img/logo/swc-black-blue.png"
                  >
                </td>
                <td colspan="2">
                  <div class="title">
                    <span v-if="encounter.is_revised">
                      REVISED<br>
                    </span>
                    {{ title }}
                  </div>
                  <div class="vid">
                    <label>VID:</label>
                    <span :class="{'redacted-text': isRedacted}">
                      {{ encounter.id }}
                    </span>
                  </div>
                  <div class="vid">
                    {{ providerName(encounter.created_by_user_id) }}
                  </div>
                </td>
              </tr>
            </table>
          </td>
          <td width="25%">
            <label>DATE OF SERVICE</label><br>
            <b>{{ formatDate(encounter.visit_date) }}</b>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <label>FACILITY</label><br>
            <span v-if="encounter.place_of_service_id"><b>{{ $store.getters['facilities/getById'](encounter.place_of_service_id).title }}</b></span>
          </td>
          <!--
          <td>
            <label>FAX</label><br>
            <span :class="{'redacted-text': isRedacted}"><b>123-456-7890</b></span>
          </td>
          -->
          <td>
            <label>VISIT TYPE</label><br>
            <b>{{ encounter.visit_type }}</b>
          </td>
        </tr>
        <tr>
          <td>
            <label>PATIENT</label><br>
            <span :class="{'redacted-text': isRedacted}"><b><patient-name :patient="patient"></patient-name></b></span>
          </td>
          <td>
            <label>GENDER</label><br>
            <span v-if="patient.gender"><b>{{ patient.gender }}</b></span>
          </td>
          <td>
            <label>DATE OF BIRTH</label><br>
            <span v-if="patient.dob"><b>{{ formatDate(patient.dob) }}</b></span>
          </td>
        </tr>
    </table>

    <!-- Deferred Care -->
    <div v-if="encounter.is_deferred_care">
        <div v-if="encounter.is_signed">
          <label>Deferred Care Reason:</label> {{ encounter.deferred_care_reason }}<br>
        </div>
        <text-area
          v-else
          v-model="encounter.deferred_care_reason"
          label="Reason patient care was deferred..."
          style="font-family: Inter, sans-serif;"
          required
          @input="deferredCareUpdated"
        ></text-area>
    </div>

    <!-- Content -->
    <div
        v-else
        v-html="encounter.final_note"
    ></div>

    <!-- Signed -->
    <div v-if="encounter.is_signed">
        <hr>
        Reviewed and electronically signed by:
        {{ providerName(encounter.signed_by_user_id) }}
        on {{ formatDateTime(encounter.signed_time) }}<br>
    </div>

    <!-- Removed TXP-181, added to final_note column instead
        Addendums
      <div
        v-if="encounter.addendums && encounter.addendums.length > 0"
        class="addendums"
      >
        <hr>
        <label>Addendum{{ encounter.addendums.length > 1 ? 's' : '' }}:</label><br>
        <div
          v-for="(item, index) in sortedAddendums"
          :key="index"
        >
          <br>{{ item.notes }}<br>
          Addendum added by:
          {{ providerName(encounter.created_by_user_id) }}
          on {{ formatDateTime(item.created) }}<br>
        </div>
      </div>-->

    <!-- Footer -->
    <div class="footer">
      <hr>
      <div>{{ appName }} © {{ $date().format('YYYY') }} | (866) 968-6380 | (310) 445-5999</div>
      <div>12021 Wilshire Blvd. #745, Los Angeles, CA 90025</div>
    </div>
  </v-card-text>
</template>

<script>
import themeConfig from '@themeConfig'

export default {
  props: {
    encounter: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      appName: themeConfig.app.name,
    }
  },
  computed: {
    title() {
      if (this.encounter.is_deferred_care) return 'Podiatry Deferred Encounter'

      return this.encounter.visit_type === 'New'
        ? 'Podiatry Assessment'
        : 'Podiatry Follow-up Assessment'
    },
    sortedAddendums() {
      const addendums = [...this.encounter.addendums]

      return addendums.sort((a, b) => (a.created > b.created && 1) || -1)
    },
    patient() {
      return this.$store.getters['patients/getById'](this.encounter.patient_id)
    }
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    formatDate(date) {
      return this.$date(date).format('MM/DD/YYYY')
    },
    formatDateTime(dateTime) {
      return this.$date(dateTime).format('M/D/YYYY h:mm:ss A')
    },
    providerName(id) {
      const provider = this.$store.getters['users/getById'](id)

      if (provider) {
        const authId = this.$store.getters['auth/user'].id
        const userSuffix = id === authId ? this.$store.getters['auth/getSuffix'] : ''

        if (userSuffix) {
          return `${provider.first_name} ${provider.last_name}, ${userSuffix}`
        } else {
          return `${provider.first_name} ${provider.last_name}`
        }
      } else {
        return 'Unknown Provider'
      }
    },
  },
}
</script>

<style>

</style>
