<template>
  <v-col class="col-sm-12">
    <app-card-actions
      no-actions
    >
      <template slot="title">
        Patient Note History
      </template>
      <v-data-table
          :headers="headers"
          :expanded="expanded"
          :items="encounterList"
          item-key="id"
          :page.sync="pagination.page"
          :items-per-page="pagination.itemsPerPage"
          :sort-by.sync="sort.by"
          :sort-desc.sync="sort.desc"
          hide-default-footer
          class="has-pagination col-sm-12 pa-2"
          loading-text="Loading... Please wait..."
          @page-count="pagination.pageCount = $event"
        >


        <!-- Practice Type -->
        <template #item.practice_type_id="{ item }">
          <span>
            {{ findPracticeType(item.practice_type_id) }}
          </span>
        </template>

        <!-- Date of Service -->
        <template #item.visit_date="{ item }">
          <span>
            {{ $date(item.visit_date).format('MM/DD/YY') }}
          </span>
        </template>

        <!-- Visit type -->
        <template #item.visit_type="{ item }">
          <span>
            {{ item.visit_type === 'New' ? 'New' :'F/U' }}
          </span>
        </template>

        <!-- Actions -->
        <template #item.actions="{item}">
          <btn
            label="VIEW NOTE"
            :icon="icons.mdiEyeOutline"
            @click="viewNote(item)"
            class="col-sm-12"
          ></btn>
        </template>

        <!-- Expanded items -->
        <!-- Reason For Visit -->
        <template #expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <label class="col-sm-3 text-right">
              Reason for visit:
            </label>
            <div class="col-sm-12">
              {{ item.visit_reason
                ? item.visit_reason.length <= 110
                ? item.visit_reason
                : item.visit_reason.substring(0, 110) + '...'
                : item.practice_type_id === 1
                  ? 'N/A'
                  : 'Not Applicable' }}
            </div>
            <!-- <text-area
              class="col-sm-12"
              readonly
              disabled
              label="Reason for visit"
              :value="item.visit_reason
                ? item.visit_reason
                : item.practice_type_id === 1
                  ? 'N/A'
                  : 'Not Applicable'"
            >
            </text-area> -->
          </td>
        </template>
      </v-data-table>

      <!-- pagination -->
      <pagination
        :page.sync="pagination.page"
        :items-per-page.sync="pagination.itemsPerPage"
        :page-count="pagination.pageCount"
      ></pagination>
    </app-card-actions>

    <v-dialog
      v-model="modalState"
      width="700"
      persistent
    >
      <v-card
        v-if="!this.encounter"
      >
      </v-card>
      <v-card
        v-else
      >
        <v-card-title
          style="display: flex;
            justify-content: space-between;
            position: sticky;
            top: 0;
            background-color: rgb(233,233,234);
            z-index: 3;"
        >

          <div class="text-h5 text-medium-emphasis ps-2">
            {{encounterTitle()}}
          </div>

          <!-- close -->
          <btn
            label="Close"
            :icon="icons.mdiClose"
            color="secondary"
            @click="modalState = false"
          ></btn>
        </v-card-title>


        <Preview
          class="pt-5"
          :encounter="encounter">
        </Preview>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import AppCardActions from '@core/components/app-card-actions/AppCardActions.vue'
import Preview from '@/views/notes/encounter-preview/Preview.vue'
import {
  mdiClose, mdiDecagramOutline, mdiClipboardOutline, mdiCloudOutline, mdiCheckDecagram,
  mdiCloseCircle, mdiClipboardCheck, mdiCloudCheckVariant, mdiEyeOutline,
} from '@mdi/js'
import TextField from '../elements/TextField.vue'
export default {
  components: { AppCardActions, Preview, TextField },
  props: {
    encounterId: {
      type: String,
      default: null,
    },
    practiceType: {
      type: Number,
      default: null,
    },
    patient: {
      type: Object,
      default: {}
    },
  },
  data() {
    return {
      modalState: false,
      icons: {
        mdiClose,
        mdiDecagramOutline,
        mdiClipboardOutline,
        mdiCloudOutline,
        mdiCheckDecagram,
        mdiCloseCircle,
        mdiClipboardCheck,
        mdiCloudCheckVariant,
        mdiEyeOutline,
      },
      headers: [
        {
          text: 'Date of Service', value: 'visit_date'
        },
        {
          text: 'Practice Type', value: 'practice_type_id'
        },
        {
          text: 'Visit Type', value: 'visit_type'
        },
        {
          text: '', value: 'actions', align: 'xsmall', sortable: false,
        },
      ],
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 5,
      },
      sort: {
        by: 'visit_date',
        desc: true,
      },
      encounter: null,
    }
  },
  watch: {
  },
  mounted() {
  },
  computed: {
    encounterList() {
      if (this.practiceType !== null) {
        return this.$store.getters['encounters/patientHistory'](this.patient.id, 365)
          .filter(enc => enc.practice_type_id === this.practiceType)
      }
      else {
        return this.$store.getters['encounters/patientHistory'](this.patient.id, 365)
      }
    },

    expanded() {
      return this.encounterList
    }
  },
  methods: {
    viewNote(item) {
      this.modalState = true
      this.$nextTick(() => {
        this.encounter = item
      })
    },
    encounterTitle() {
      switch (this.encounter.practice_type_id) {
        // Wound Care
        case 1:
          return this.encounter.is_deferred_care
            ? 'Wound Care Deferred Encounter'
            : 'Wound Care Surgical Note'

        // Psychiatry
        case 2:
          if (this.encounter.is_deferred_care) return 'Psychiatry Deferred Encounter'

          return this.encounter.visit_type === 'New'
            ? 'Psychiatry Assessment'
            : 'Psychiatry Follow-up Assessment'

        // Psychology
        case 3:
          if (this.encounter.is_deferred_care) return 'Psychology Deferred Encounter'

          return this.encounter.visit_type === 'New'
            ? 'Psychology Diagnostic Assessment'
            : 'Psychology Follow-up Assessment'

        // Primary Care
        case 4:
          if (this.encounter.is_deferred_care) return 'Primary Care Deferred Encounter'

          return this.encounter.visit_type === 'New'
            ? 'Primary Care Assessment'
            : 'Primary Care Follow-up Assessment'

        // Podiatry
        case 7:
          if (this.encounter.is_deferred_care) return 'Podiatry Deferred Encounter'

          return this.encounter.visit_type === 'New'
            ? 'Podiatry Assessment'
            : 'Podiatry Follow-up Assessment'
      }
    },
    providerName(id) {
      const provider = this.$store.getters['users/getById'](id)

      if (provider) {
        const authId = this.$store.getters['auth/user'].id
        const userSuffix = id === authId ? this.$store.getters['auth/getSuffix'] : ''

        if (userSuffix) {
          return `${provider.first_name} ${provider.last_name}, ${userSuffix}`
        } else {
          return `${provider.first_name} ${provider.last_name}`
        }
      } else {
        return 'Unknown Provider'
      }
    },
    findPracticeType(id) {
      switch (id) {
        case 1:
          return 'WoundCare'
        case 2:
          return 'Psychiatry'
        case 3:
          return 'Psychology'
        case 4:
          return 'PrimaryCare'
        case 7:
          return 'Podiatry'
      }
    },
    getPatient(patientId) {
      return this.$store.getters['patients/getById'](patientId)
    },
  }
}

</script>

<style lang="scss">
</style>
