<template>
  <component
    :is="toRender()"
    :encounter="encounter"
  ></component>
</template>

<script>
import WoundCare from '@/views/notes/encounter-preview/types/WoundCare.vue'
import Podiatry from '@/views/notes/encounter-preview/types/Podiatry.vue'
import PrimaryCare from '@/views/notes/encounter-preview/types/PrimaryCare.vue'
import Psychiatry from '@/views/notes/encounter-preview/types/Psychiatry.vue'
import Psychology from '@/views/notes/encounter-preview/types/Psychology.vue'

export default {
  components: {
    WoundCare, Podiatry, PrimaryCare, Psychiatry, Psychology
  },
  props: {
    encounter: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
    }
  },
  computed: {
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    toRender() {
      switch (this.encounter.practice_type_id) {
        case 1:
          return 'WoundCare'
        case 2:
          return 'Psychiatry'
        case 3:
          return 'Psychology'
        case 4:
          return 'PrimaryCare'
        case 7:
          return 'Podiatry'
      }
    }
  },
}
</script>
