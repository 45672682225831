import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCol,{staticClass:"col-sm-12"},[_c('app-card-actions',{attrs:{"no-actions":""}},[_c('template',{slot:"title"},[_vm._v(" Patient Note History ")]),_c(VDataTable,{staticClass:"has-pagination col-sm-12 pa-2",attrs:{"headers":_vm.headers,"expanded":_vm.expanded,"items":_vm.encounterList,"item-key":"id","page":_vm.pagination.page,"items-per-page":_vm.pagination.itemsPerPage,"sort-by":_vm.sort.by,"sort-desc":_vm.sort.desc,"hide-default-footer":"","loading-text":"Loading... Please wait..."},on:{"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)},"update:sortBy":function($event){return _vm.$set(_vm.sort, "by", $event)},"update:sort-by":function($event){return _vm.$set(_vm.sort, "by", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.sort, "desc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.sort, "desc", $event)},"page-count":function($event){_vm.pagination.pageCount = $event}},scopedSlots:_vm._u([{key:"item.practice_type_id",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.findPracticeType(item.practice_type_id))+" ")])]}},{key:"item.visit_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$date(item.visit_date).format('MM/DD/YY'))+" ")])]}},{key:"item.visit_type",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.visit_type === 'New' ? 'New' :'F/U')+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('btn',{staticClass:"col-sm-12",attrs:{"label":"VIEW NOTE","icon":_vm.icons.mdiEyeOutline},on:{"click":function($event){return _vm.viewNote(item)}}})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('label',{staticClass:"col-sm-3 text-right"},[_vm._v(" Reason for visit: ")]),_c('div',{staticClass:"col-sm-12"},[_vm._v(" "+_vm._s(item.visit_reason ? item.visit_reason.length <= 110 ? item.visit_reason : item.visit_reason.substring(0, 110) + '...' : item.practice_type_id === 1 ? 'N/A' : 'Not Applicable')+" ")])])]}}])}),_c('pagination',{attrs:{"page":_vm.pagination.page,"items-per-page":_vm.pagination.itemsPerPage,"page-count":_vm.pagination.pageCount},on:{"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.pagination, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.pagination, "itemsPerPage", $event)}}})],2),_c(VDialog,{attrs:{"width":"700","persistent":""},model:{value:(_vm.modalState),callback:function ($$v) {_vm.modalState=$$v},expression:"modalState"}},[(!this.encounter)?_c(VCard):_c(VCard,[_c(VCardTitle,{staticStyle:{"display":"flex","justify-content":"space-between","position":"sticky","top":"0","background-color":"rgb(233,233,234)","z-index":"3"}},[_c('div',{staticClass:"text-h5 text-medium-emphasis ps-2"},[_vm._v(" "+_vm._s(_vm.encounterTitle())+" ")]),_c('btn',{attrs:{"label":"Close","icon":_vm.icons.mdiClose,"color":"secondary"},on:{"click":function($event){_vm.modalState = false}}})],1),_c('Preview',{staticClass:"pt-5",attrs:{"encounter":_vm.encounter}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }